<template>
  <div class="mb-7 pb-7">
      <v-row class="" no-gutters align="center">
        <v-col class="mt-2" cols="12"  md="2" >
          <h5>{{ $t("message.dashboard-title") }}</h5>
        </v-col>
        <v-col cols="12"  md="5">
          <v-row>
            <v-col>
              <span>{{ $t("message.delivery-date") }}</span>
              <v-select
                outlined
                clearable
                :items="deliveryDate"
                item-text="delivery_date_label"
                item-value="delivery_date_value"
                class="mt-1 font-size filter-input-class"
                v-model="filter.delivDate"
              ></v-select>
            </v-col>
            <v-col >
              <span>{{ $t("message.store-name") }}</span>
              <v-select
                outlined
                clearable
                :items="storeListData"
                item-text="store_name"
                item-value="id"
                class="mt-1 font-size filter-input-class"
                v-model="filter.storeId"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12"  md="5">
          <v-row justify="end">
            <v-col cols="12" md="5" class="pt-2">
              <v-btn
                v-if="!routeName"
                width="150"
                class="white--text"
                color="indigo"
                style="float: right;"
                @click="printNote"
                :loading="isLoading"
              >
                <v-icon left>
                  mdi-printer-outline
                </v-icon>
                <span v-if="is_completed">{{ $t("message.print-receipt-all") }}</span>
                <span v-else>{{ $t("message.print-invoice-all") }}</span>
              </v-btn>
            </v-col>
            <v-col cols="12" md="5"  class="pt-2">
              <v-btn
                v-if="is_completed"
                width="150"
                class="white--text"
                color="success"
                style="float: right;"
                @click="reqToDelivered"
                :loading="isLoading"
              >
                <v-icon left>
                  mdi-moped-outline
                </v-icon>
                {{ $t("message.delivered-order-all") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
  </div>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
import moment from "moment";

export default {
  name: "FilterDashboard",
  data: () => ({
    iconSearch: mdiMagnify,
    // delivDate: moment(new Date()).format("YYYY-MM-DD"),
    filter: {
      storeId: "",
      delivDate: "",
    },
    is_completed: true,
    routeName: "",
    status: "",
    isLoading: false,
    store: [],
    firstLoad: false,

    // deliveryDate: [],
    // storeListData: []
  }),
  created() {
    this.dispatchStoreList();
  },
  watch: {
    filter: {
      handler: function(newVal) {
        this.filter.storeId = newVal.storeId;
        this.filter.delivDate = newVal.delivDate;
        this.filterDahsboard();
      },
      deep: true,
    },
  },
  computed: {
    storeListData() {
      return this.$store.state.shop_module.shopAllResult;
    },
    deliveryDate() {
      return this.$store.state.dashboard_module.deliveryDates;
    },
  },
  methods: {
    async dispatchStoreList() {
      await this.$store.dispatch("shop_module/fetchShopAll");
    },
    async dispatchDeliveryDates() {
      await this.$store.dispatch("dashboard_module/deliveryDate");
    },
    reqToDelivered(){
      this.$store.dispatch("dashboard_module/deliveredOrderComplete", {
        order_id: "",
        delivery_datetime:  this.filter.delivDate == undefined || null ? "" : this.filter.delivDate ,
        store_id: this.filter.storeId == undefined || null ? "" : this.filter.storeId,

      })
    },

    async filterDahsboard() {
      let routeName = this.$route.name;
      if (routeName == "OrderReceived") {
        await this.$store.dispatch("dashboard_module/dashboardOrderReceived", {
          deliveryDate:
            this.filter.delivDate !== null ? this.filter.delivDate : "",
          store_id: this.filter.storeId !== null ? this.filter.storeId : "",
        });
      } else if (routeName == "Completed") {
        await this.$store.dispatch("dashboard_module/dashboardOrderComplete", {
          deliveryDate:
            this.filter.delivDate !== null ? this.filter.delivDate : "",
          store_id: this.filter.storeId !== null ? this.filter.storeId : "",
        });
      } else {
        await this.$store.dispatch(
          "dashboard_module/dashboardOrderProcessing",
          {
            deliveryDate:
              this.filter.delivDate !== null ? this.filter.delivDate : "",
            store_id: this.filter.storeId !== null ? this.filter.storeId : "",
          }
        );
      }
    },
    checkRoute() {
      let name = this.$route.name;
      if (name === "Processing") {
        this.routeName = true;
        this.is_completed = false;
      } else if (name === "Completed") {
        this.status = 4;
        this.is_completed = true;
        this.routeName = false;
      } else if (name === "OrderReceived") {
        this.status = 2;
        this.is_completed = false;
        this.routeName = false;
      }
    },
    async printNote() {
      this.isLoading = true;
      let resp = await this.$store.dispatch("dashboard_module/printOrder", {
        status: this.status,
        deliveryDate:
          this.filter.delivDate === null ? "" : this.filter.delivDate,
        storeId: this.filter.storeId == null ? "" : this.filter.storeId,
      });

      if (resp.status == 200) {
        this.isLoading = false;
        window.open(resp.redirect_url, "_blank");
      }
      this.isLoading = false;
      // this.initFilter()
    },
    async initFilter() {
      await this.$store.dispatch("dashboard_module/shopAll");
      await this.$store.dispatch("dashboard_module/deliveryDate");

      // this.deliveryDate = this.$store.state.dashboard_module.deliveryDates
      // this.storeListData = this.$store.state.shop_module.shopAllResult

      let route = this.$route.name;
      if (route === "Processing") {
        this.getInitData();
      } else if (route === "OrderReceived") {
        this.getInitData();
      } else if (route === "Completed") {
        this.getInitData();
      }
    },
    getInitData() {
      this.filter.storeId = this.$store.state.dashboard_module.filterDashboard.storeId;
      this.filter.delivDate = this.$store.state.dashboard_module.filterDashboard.initDelivDate;
    },
  },
  created() {
    this.dispatchStoreList();
    this.dispatchDeliveryDates();
    this.checkRoute();
    this.initFilter();
    // this.dispatchShop()
  },
};
</script>

<style>

.filter-input-class .v-input__slot .v-text-field__slot input{
  padding: 8px 0px 8px 0px;
}
.filter-input-class .v-input__control .v-select__slot .v-select__selections input {
  padding: 8px 0px 8px 0px;
}
.filter-input-class .v-input__control .v-select__slot input {
  padding: 8px 0px 8px 0px;
}
.font-size {
  font-size: 12px !important;
}
.btn-search {
  border-radius: 20px;
}
.v-select.v-text-field--outlined:not(.v-text-field--single-line)
  .v-select__selections {
  padding: 0px !important;
}
.v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 0px !important;
  align-self: center !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 0px !important;
  align-self: center !important;
}
.col-filter-input {
  padding-right: 10px !important;
  padding-left: 0px !important;
}
.text-field-class .v-input__control input {
  font-size: 14px;
}
</style>
