<template>
  <div class="mx-3">
    <FilterDashboard />

    <div>
      <DasboardNav />
    </div>

    <template v-if="isTableLoading">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </template>

    <table id="RoundedTable">
      <thead class="px-4 py-4 ">
        <tr class="px-4 py-4 text-center tr-class" style="height: 50px">
          <th class="font-class align-text" style="width: 18%;">
            {{ $t("message.order-number") }}
          </th>
          <th class="font-class align-text" style="width: 20%;">
            {{ $t("message.account-id") }}
          </th>
          <th class="font-class align-text" style="width: 15%;">
            {{ $t("message.order-count") }}
          </th>
          <th class="font-class align-text" style="width: 10%;">
            {{ $t("message.order-total-fee") }}
          </th>
          <th class="font-class align-text" style="width: 13%;">
            {{ $t("message.order-date") }}
          </th>
          <th class="font-class align-text" style="width: 15%;">
            {{ $t("message.delivery-date") }}
          </th>
          <th style="width: 10%;"></th>
        </tr>
      </thead>
      <template v-if="orderCompleted.length == 0">
        <tbody>
          <tr class="text-center">
            <td colspan="7" style="text-align: center;">
              {{ $t("message.no-data") }}
            </td>
          </tr>
        </tbody>
      </template>
      <template v-else>
        <tbody v-for="(order, id) in orderCompleted" :key="id">
          <tr
            :class="{ opened: opened.includes(order.id) }"
            style="border-bottom: 1px solid #BDBDBD"
          >
            <template>
              <td style="text-align: center;">
                <a href="" @click.prevent="toOrderDetail(order.id)">{{
                  order.order_no
                }}</a>
              </td>
              <td style="text-align: center;">
                <a href="" @click.prevent="toCustomerDetail(order)">{{
                  order.customer_name
                }}</a>
              </td>
            </template>
            <td style="text-align: center;">{{ order.order_count }}</td>
            <td style="text-align: center;">
              {{ moneyFormat(parseInt(order.total_order_price)) }}
            </td>
            <td style="text-align: center;">{{ order.created_at }}</td>
            <td style="text-align: center;">{{ order.delivery_at }}</td>
            <td style="text-align: center;" @click="toggle(order.id)">
              <v-btn icon style="text-transform: none;">
                <v-icon v-if="!opened.includes(order.id)" left>{{
                  "mdi-chevron-down"
                }}</v-icon>
                <v-icon v-else left>{{ "mdi-chevron-up" }}</v-icon>
                {{ $t("message.detail") }}
              </v-btn>
            </td>
          </tr>

          <template v-if="opened.includes(order.id)" class="">
            <tr
              id="example1"
              class="text-center expand-table-class "
              style="background-color: #E8EAF6;margin-top: 3em; margin-bottom: 3em;border-bottom: 0.5px solid #BDBDBD"
              elavation="1"
            >
              <th
                class="font-class"
                style="padding-top: 1em;padding-bottom: 1em;"
              >
                {{ $t("message.product-id") }}
              </th>
              <th
                class="font-class"
                style="padding-top: 1em;padding-bottom: 1em;"
              >
                {{ $t("message.product-name") }}
              </th>
              <th
                class="font-class"
                style="padding-top: 1em;padding-bottom: 1em;"
              >
                {{ $t("message.product-code") }}
              </th>
              <th
                class="font-class"
                style="padding-top: 1em;padding-bottom: 1em;"
              >
                {{ $t("message.qty") }}
              </th>
              <th
                class="font-class"
                style="padding-top: 1em;padding-bottom: 1em;"
              >
                {{ $t("message.price-display-per-item") }}
              </th>
              <th
                class="font-class"
                style="padding-top: 1em;padding-bottom: 1em;"
              >
                {{ $t("message.price-display") }}
              </th>
              <th
                class="font-class"
                style="padding-top: 1em;padding-bottom: 1em;"
              >
                {{ $t("message.tax-type-display") }}
              </th>
            </tr>
            <tr
              id="example2"
              class="hover-class"
              v-for="(detail, idx) in order.details"
              :key="idx"
              style="background-color: #E8EAF6;border-bottom: 0.5px solid #BDBDBD"
            >
              <td
                class="font-class"
                style="padding: 8px 0px 8px 0px;text-align: center;"
              >
                {{ detail.item_id }}
              </td>
              <td
                class="font-class"
                style="padding: 8px 0px 8px 0px;text-align: justify;"
              >
                {{ detail.item_name }}
              </td>
              <td
                class="font-class"
                style="padding: 8px 0px 8px 0px;text-align: center;"
              >
                {{ detail.item_code }}
              </td>
              <td
                class="font-class"
                style="padding: 8px 0px 8px 0px;text-align: center;"
              >
                {{ detail.qty }}
              </td>
              <td
                class="font-class"
                style="padding-bottom: 1em;text-align: center;"
              >
                {{ detail.price_display_peritem }}
              </td>
              <td
                class="font-class"
                style="padding-bottom: 1em;text-align: center;"
              >
                {{ detail.price_display }}
              </td>
              <td
                class="font-class"
                style="padding-bottom: 1em;text-align: center;"
              >
                {{ detail.tax_type_display }}
              </td>
            </tr>

            <tr
              id="example5"
              class="hover-class"
              style="background-color: #E8EAF6; "
              v-if="order.subtotal > 0"
            >
              <td colspan="5"></td>
              <td class="pe-8" style="text-align:end;">
                {{ $t("message.subtotal") }}
              </td>
              <td class="ps-8" style="text-align:start;">
                {{ order.subtotal }}
              </td>
            </tr>
            <tr
              id="example5"
              class="hover-class"
              style="background-color: #E8EAF6; "
              v-if="order.subtotal_exclude_taxable_value_8 > 0"
            >
              <td colspan="5"></td>
              <td class="pe-8" style="text-align:end;">
                {{ $t("message.subtotal-exclude-taxable-value-8") }}
              </td>
              <td colspan="1" class="ps-8" style="text-align:start;">
                {{ toFixed(order.subtotal_exclude_taxable_value_8) }}
              </td>
            </tr>
            <tr
              id="example5"
              class="hover-class"
              style="background-color: #E8EAF6; "
              v-if="order.subtotal_consumption_tax_8 > 0"
            >
              <td colspan="5"></td>
              <td class="pe-8" style="text-align:end;">
                {{ $t("message.subtotal-consumption-tax-8") }}
              </td>
              <td colspan="1" class="ps-8" style="text-align:start;">
                {{ toFixed(order.subtotal_consumption_tax_8) }}
              </td>
            </tr>
            <tr
              id="example5"
              class="hover-class"
              style="background-color: #E8EAF6; "
              v-if="order.subtotal_exclude_taxable_value_10 > 0"
            >
              <td colspan="5"></td>
              <td class="pe-8" style="text-align:end;">
                {{ $t("message.subtotal-exclude-taxable-value-10") }}
              </td>
              <td colspan="1" class="ps-8" style="text-align:start;">
                {{ toFixed(order.subtotal_exclude_taxable_value_10) }}
              </td>
            </tr>
            <tr
              id="example5"
              class="hover-class"
              style="background-color: #E8EAF6; "
              v-if="order.subtotal_consumption_tax_10 > 0"
            >
              <td colspan="5"></td>
              <td class="pe-8" style="text-align:end;">
                {{ $t("message.subtotal-consumption-tax-10") }}
              </td>
              <td colspan="1" class="ps-8" style="text-align:start;">
                {{ toFixed(order.subtotal_consumption_tax_10) }}
              </td>
            </tr>
            <tr
              id="example5"
              class="hover-class"
              style="background-color: #E8EAF6; "
              v-if="order.subtotal_include_taxable_value_8 > 0"
            >
              <td colspan="5"></td>
              <td class="pe-8" style="text-align:end;">
                {{ $t("message.subtotal-include-taxable-value-8") }}
              </td>
              <td colspan="1" class="ps-8" style="text-align:start;">
                {{ toFixed(order.subtotal_include_taxable_value_8) }}
              </td>
            </tr>
            <tr
              id="example5"
              class="hover-class"
              style="background-color: #E8EAF6; "
              v-if="order.subtotal_consumption_taxable_8 > 0"
            >
              <td colspan="5"></td>
              <td class="pe-8" style="text-align:end;">
                {{ $t("message.subtotal-consumption-taxable-8") }}
              </td>
              <td colspan="1" class="ps-8" style="text-align:start;">
                {{ toFixed(order.subtotal_consumption_taxable_8) }}
              </td>
            </tr>
            <tr
              id="example5"
              class="hover-class"
              style="background-color: #E8EAF6; "
              v-if="order.subtotal_include_taxable_value_10 > 0"
            >
              <td colspan="5"></td>
              <td class="pe-8" style="text-align:end;">
                {{ $t("message.subtotal-include-taxable-value-10") }}
              </td>
              <td colspan="1" class="ps-8" style="text-align:start;">
                {{ toFixed(order.subtotal_include_taxable_value_10) }}
              </td>
            </tr>
            <tr
              id="example5"
              class="hover-class"
              style="background-color: #E8EAF6; "
              v-if="order.subtotal_consumption_taxable_10 > 0"
            >
              <td colspan="5"></td>
              <td class="pe-8" style="text-align:end;">
                {{ $t("message.subtotal-consumption-taxable-10") }}
              </td>
              <td colspan="1" class="ps-8" style="text-align:start;">
                {{ toFixed(order.subtotal_consumption_taxable_10) }}
              </td>
            </tr>
            <tr
              id="example5"
              class="hover-class"
              style="background-color: #E8EAF6; "
              v-if="order.subtotal_non_taxable_value > 0"
            >
              <td colspan="5"></td>
              <td class="pe-8" style="text-align:end;">
                {{ $t("message.subtotal-non-taxable-value") }}
              </td>
              <td colspan="1" class="ps-8" style="text-align:start;">
                {{ toFixed(order.subtotal_non_taxable_value) }}
              </td>
            </tr>
            <tr
              id="example5"
              class="hover-class"
              style="background-color: #E8EAF6; "
            >
              <td colspan="5"></td>
              <td class="pe-8" style="text-align:end;">
                {{ $t("message.delivery-fee-display") }}
              </td>
              <td colspan="1" class="ps-8" style="text-align:start;">
                {{ toFixed(order.delivery_fee_display) }}
              </td>
            </tr>
            <tr
              id="example5"
              class="hover-class"
              style="background-color: #E8EAF6; "
              v-if="order.total_amount_w_delivery_fee_per_store > 0"
            >
              <td colspan="5"></td>
              <td class="pe-8" style="text-align:end;">
                {{ $t("message.total-amount-delivery-fee-store") }}
              </td>
              <td colspan="1" class="ps-8" style="text-align:start;">
                {{ toFixed(order.total_amount_w_delivery_fee_per_store) }}
              </td>
            </tr>
            <tr
              id="example4"
              class="hover-class"
              style="background-color: #E8EAF6"
            >
              <td colspan="5" class="pl-5">
                <span>【備考】</span>
                <p v-if="order.order_shipping_type == 1">置き配を希望する</p>
                <pre>{{ order.notes }}</pre>
              </td>
              <td
                colspan="2"
                style="text-align: end; padding-top: 1em; padding-bottom: 2em"
              >
                <v-btn
                  depressed
                  small
                  color="#7986cb"
                  class="white--text"
                  style="text-transform: none; margin-right: 20px;margin-bottom:4px;"
                  @click.prevent="reqToDelivered(order.id)"
                  :disabled="order.is_delivery_now == 0"
                >
                  <v-icon class="me-3">
                    mdi-moped-outline
                  </v-icon>
                  {{ $t("message.delivered-order") }}
                </v-btn>
                <v-btn
                  depressed
                  color="indigo"
                  class="white--text"
                  style="margin-right: 20px;margin-bottom:4px;"
                  @click="printNote(null, order.id, order.delivery_at)"
                >
                  <v-icon class="me-3">
                    mdi-printer-outline
                  </v-icon>
                  {{ $t("message.print-receipt") }}
                </v-btn>
                <v-btn
                  depressed
                  color="indigo"
                  class="white--text"
                  style="margin-right: 20px"
                  @click="printNote(1, order.id, order.delivery_at)"
                >
                  <v-icon class="me-3">
                    mdi-printer-outline
                  </v-icon>
                  {{ $t("message.print-invoice") }}
                </v-btn>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </table>
  </div>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
import FilterDashboard from "../../components/FilterDashboard.vue";
import DasboardNav from "../../components/DasboardNav.vue";
import moneyFormat from "../../helpers/moneyFormat";
export default {
  name: "Completed",
  components: {
    FilterDashboard,
    DasboardNav,
  },
  data: () => ({
    iconSearch: mdiMagnify,
    option: {},
    opened: [],
    isExpanded: false,
    isLoading: false,
    isTableLoading: true,
    rows: [
      { id: 1, name: "Bill", handle: "bill" },
      { id: 2, name: "Bob", handle: "bob" },
      { id: 3, name: "Jim", handle: "jim" },
      { id: 4, name: "Leroy", handle: "leroy" },
    ],
  }),
  computed: {
    orderCompleted() {
      console.log(this.$store.state.dashboard_module.dashboardCompleted);
      return this.$store.state.dashboard_module.dashboardCompleted;
    },
  },
  created() {
    this.dispatchOrderComplete();
  },
  mounted() {},
  methods: {
    toOrderDetail(order_id) {
      this.$router.push(`/order/${order_id}`);
    },
    toCustomerDetail(order) {
      this.$router.push(`/customer/detail/${order.customer_id}`);
    },
    toFixed(money) {
      return money.toFixed(0);
    },
    async reqToDelivered(orderId) {
      let resp = await this.$store.dispatch(
        "dashboard_module/deliveredOrderComplete",
        {
          order_id: orderId,
          delivery_datetime: "",
          store_id: "",
        }
      );
    },
    moneyFormat(money) {
      return moneyFormat(money);
    },
    toggle(id) {
      const index = this.opened.indexOf(id);

      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },
    async dispatchOrderComplete() {
      this.isTableLoading = true;
      await this.$store.dispatch("dashboard_module/shopAll");
      await this.$store.dispatch("dashboard_module/deliveryDate");
      await this.$store.dispatch("dashboard_module/dashboardOrderComplete");
      this.isTableLoading = false;
    },
    async printNote(type, orderId) {
      let routeName = this.$route.name;
      // this.isLoading = true;
      let resp = await this.$store.dispatch("dashboard_module/printOrder", {
        status: 4,
        orderId: orderId,
        route: routeName,
        invoice_type: type,
      });

      if (resp.status == 200) {
        // this.isLoading = false;
        window.open(resp.redirect_url, "_blank");
      }
      // this.isLoading = false;
    },
  },
};
</script>

<style scoped>
#example1:hover {
  background-color: #e8eaf6 !important;
}
#example2:hover {
  background: #e8eaf6 !important;
}
#example3:hover {
  background: #e8eaf6 !important;
}
#example4:hover {
  background: #e8eaf6 !important;
}
#example5:hover {
  background: #e8eaf6 !important;
}
#example6:hover {
  background: #e8eaf6 !important;
}
tr-class {
  background: #7986cb !important;
}
font-class {
  font-size: 14px;
}
thead {
  background: #7986cb !important;
  text-transform: capitalize;
}
thead tr th {
  color: white !important;
  font-size: 15px !important;
}
.v-data-footer-top {
  border-top: none !important;
}
.align-text {
  text-align: center !important;
  vertical-align: middle !important;
}
table {
  width: 100%;
  border-collapse: collapse;
}
tr:last-child td:first-child {
  border-bottom-left-radius: 20px;
}
tr:last-child td:last-child {
  border-bottom-right-radius: 20px;
}
tbody tr:hover:not(.v-table__expanded__content) {
  background: transparent !important;
}
</style>
