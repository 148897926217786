<template>
  <div>
    <v-card class="d-flex justify-center text-center" flat no-gutters tile>
      <v-btn
        :class="
          isOrderReceived
            ? 'btn-active pa-2 header-nav mx-2 btn'
            : 'pa-2 header-nav mx-2 btn'
        "
        outlined
        @click.prevent="$router.push('/').catch(()=>{});"
        tile
        style="border: 1px solid grey;border-radius: 10px 10px 0px 0px; width: 30%;"
      >
        {{ $t("message.order-received") }}
      </v-btn>
      <v-btn
        :class="
          isProcessing
            ? 'btn-active pa-2 header-nav mx-2 btn'
            : 'pa-2 header-nav mx-2 btn'
        "
        @click.prevent="$router.push('/dashboard/processing').catch(()=>{});"
        outlined
        tile
        style="border: 1px solid grey;border-radius: 10px 10px 0px 0px;width: 30%;"
      >
        {{ $t("message.processing") }}
      </v-btn>
      <v-btn
        :class="
          isCompleted
            ? 'btn-active pa-2 header-nav mx-2 btn'
            : 'pa-2 header-nav mx-2 btn'
        "
        @click.prevent="$router.push('/dashboard/completed').catch(()=>{});"
        outlined
        tile
        style="border: 1px solid grey;border-radius: 10px 10px 0px 0px;width: 30%;"
      >
        {{ $t("message.completed") }}
      </v-btn>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "DasboardNav",
  props: {
    link: Array,
  },
  data() {
    return {
      tab: null,
      isCompleted: false,
      isOrderReceived: false,
      isProcessing: false,
    };
  },
  computed: {
    links() {
      return [
        {
          text: this.$t("message.order-received"),
          route: "/categoryTop",
        },
        {
          text: this.$t("message.processing"),
          route: "/product/favorites",
        },
        {
          text: this.$t("message.completed"),
          route: "/orderHistory",
        },
      ];
    },
  },
  methods: {
    handleRoute(listRoute) {
      if (listRoute == this.$router.history.current.path) {
        return;
      } else {
        this.$router.push(listRoute);
      }
    },
    checkRoute() {
      let name = this.$route.name;
      if (name === "Completed") {
        this.isCompleted = true;
      } else if (name === "OrderReceived") {
        this.isOrderReceived = true;
      } else if (name === "Processing") {
        this.isProcessing = true;
      }
    },
  },

  created() {
    this.checkRoute();
  },
};
</script>

<style>
.btn-active {
  background-color: #3949ab !important;
  color: white !important;
}
.btn {
  text-transform: capitalize !important;
}
</style>
